// Generated by Framer (2b47498)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-WJXZo"

const variantClassNames = {YutbElsOp: "framer-v-uatbwm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "YutbElsOp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.article {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uatbwm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YutbElsOp"} ref={ref ?? ref1} style={{backgroundColor: "rgb(63, 3, 29)", ...style}}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 628) - 0) - 640) / 2)))), pixelHeight: 380, pixelWidth: 1280, positionX: "center", positionY: "center", sizes: `max(${componentViewport?.width || "100vw"}, 1px)`, src: "https://framerusercontent.com/images/tSpom4F0sOEGoVm9tYxXBBBItk.png", srcSet: "https://framerusercontent.com/images/tSpom4F0sOEGoVm9tYxXBBBItk.png?scale-down-to=512 512w,https://framerusercontent.com/images/tSpom4F0sOEGoVm9tYxXBBBItk.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/tSpom4F0sOEGoVm9tYxXBBBItk.png 1280w"}} className={"framer-1k0f1kn"} layoutDependency={layoutDependency} layoutId={"ou8JAwkGn"} style={{borderBottomLeftRadius: 20, borderTopRightRadius: 20}}/></motion.article></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WJXZo.framer-s9yeab, .framer-WJXZo .framer-s9yeab { display: block; }", ".framer-WJXZo.framer-uatbwm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 628px; justify-content: center; padding: 0px; position: relative; width: 1246px; }", ".framer-WJXZo .framer-1k0f1kn { flex: 1 0 0px; height: 640px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WJXZo.framer-uatbwm { gap: 0px; } .framer-WJXZo.framer-uatbwm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-WJXZo.framer-uatbwm > :first-child { margin-left: 0px; } .framer-WJXZo.framer-uatbwm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 628
 * @framerIntrinsicWidth 1246
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDAU6lSNHZ: React.ComponentType<Props> = withCSS(Component, css, "framer-WJXZo") as typeof Component;
export default FramerDAU6lSNHZ;

FramerDAU6lSNHZ.displayName = "Imagen1Slideshow";

FramerDAU6lSNHZ.defaultProps = {height: 628, width: 1246};

addFonts(FramerDAU6lSNHZ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})